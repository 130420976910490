<template>
  <div v-if="isShow" class="mask" @click="closePlayer">
    <div class="video-box">
      <div>
        <img class="close" src="../../assets/support/gb@2x.png" alt="" />
        <el-button @click.stop  @click="backVideoList" v-if="isPlay" class="back">返回</el-button>
      </div>
      <div v-if="!isPlay" class="cover" @click.stop>
        <img :src="videoImg" alt="" />
        <img @click="playVideo" src="../../assets/index/bf.png" alt="" />
      </div>
      <div v-if="isPlay" class="video" @click.stop>
        <video
          width="100%"
          height="100%"
          controls="controls"
          autoplay="autoplay"
          :src="videoSrc"
        >
          <!-- <source src="/i/movie.ogg" type="video/ogg" />
          <source :src="videoSrc" type="video/mp4" />
          <source src="/i/movie.webm" type="video/webm" />
          <object data="/i/movie.mp4" width="320" height="240">
            <embed width="320" height="240" src="/i/movie.swf" />
          </object> -->
        </video>
      </div>
      <!-- 侧边栏 -->
      <div class="aside" @click.stop v-if="showAside">
        <div>
          <img
            class="arrow"
            src="../../assets/support/jt4-up.png"
            alt=""
            @click="clickLast()"
          />
        </div>
        <div class="list">
          <div
            class="box"
            v-for="(item, i) in arrayWithIndex.slice(start, end)"
            :key="i"
          >
            <img
              :class="videoIndex == item.index ? 'img imgactive' : 'img'"
              :src="item.item.img"
              alt=""
              @click="clickImg(item.index)"
            />
            <div class="name">{{ item.item.name }}</div>
          </div>
        </div>
        <div>
          <img
            class="arrow"
            src="../../assets/support/jt4-down.png"
            alt=""
            @click="clickNext()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: ["isShow"],
  data() {
    return {
      isPlay: false,
      // 侧边栏是否展示
      showAside: false,
      start: 0,
      end: 3,
      videoList: [
        {
          img: require("../../assets/cases/5.png"),
          name: "视频1",
          src: 'https://oss.zijingshuke.com/1706596311124.mp4',
        },
        {
          img: require("../../assets/cases/4.png"),
          name: "视频2",
          src: 'https://oss.zijingshuke.com/1706596249018.mp4',
        },
        {
          img: require("../../assets/cases/6.png"),
          name: "视频3",
          src:  'https://oss.zijingshuke.com/1706596355015.mp4',
        },
        {
          img: require("../../assets/cases/7.png"),
          name: "视频4",
          src: 'https://oss.zijingshuke.com/1706596406528.mp4',
        },
      ],
      videoIndex: 0,
      listLength: 0,
      videoSrc:'',
      videoImg:''
    };
  },
  
  created() {
    this.listLength = this.videoList.length;
    if (this.listLength > 1) {
      this.showAside = true;
    } else {
      this.showAside = false;
    }
  },
  computed: {
    arrayWithIndex() {
      return this.videoList.map((item, index) => {
        return { index, item };
      });
    },
  },
  mounted() {
    this.videoSrc = this.videoList[0].src;
    this.videoImg = this.videoList[0].img;
  },
  methods: {
    closePlayer() {
      this.isPlay = false;
      this.showAside = true;
      // 子组件修改父组件的值
      this.$emit("closeHandler");
    },
   
    // 上一个
    clickLast() {
      if (this.start > 0) {
        this.videoIndex--;
        this.start--;
        this.end--;
        this.videoImg = this.videoList[this.videoIndex].img;
      } else {
        if (this.videoIndex > 0) {
          this.videoIndex--;
          this.videoImg = this.videoList[this.videoIndex].img;
        } else {
          return;
        }
      }
    },
    // 下一个
    clickNext() {
      if (this.listLength == 2) {
        if (this.videoIndex > 0) {
          return;
        } else {
          this.videoIndex++;
          this.videoImg = this.videoList[this.videoIndex].img;
        }
      } else {
        if (this.videoIndex < 2) {
          this.videoIndex++;
          this.videoImg = this.videoList[this.videoIndex].img;
        } else {
          // console.log(this.videoIndex)

          if (this.end < this.listLength) {
            this.videoIndex++;
            this.start++;
            this.end++;
            this.videoImg = this.videoList[this.videoIndex].img;
          }
        }
      }
      console.log("this.videoIndex:" + this.videoIndex);
      console.log("end:" + this.end);
      console.log("start:" + this.start);
    },
    // 点击视频进行切换
    clickImg(i) {
      this.videoIndex = i;
      this.videoSrc = this.videoList[this.videoIndex].src;
      this.videoImg = this.videoList[this.videoIndex].img;
      console.log(this.videoSrc)
    },
    // 点击播放视频
    playVideo(){
      this.showAside = false;
      this.isPlay = true;
      this.videoSrc = this.videoList[this.videoIndex].src
      this.videoImg = this.videoList[this.videoIndex].img;
    },
    // 返回播放列表
    backVideoList(){
      this.isPlay = false;
      this.showAside = true;
    },
  },
};
</script>

<style scoped lang="scss">
.mask {
  position: absolute;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.video-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13rem;
  height: 7.3rem;
  // background-color: #7c3434;
  /* 侧边栏 */
  .aside {
    width: 2.5rem;
    height: 7.3rem;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    .arrow {
      width: 0.3rem;
      margin: 0.1rem 0;
    }
    .list {
      width: 2.5rem;
      height: 6.3rem;
      overflow: hidden;
      .box {
        margin-bottom: 0.3rem;
        .img {
          width: 2rem;
        }
        .imgactive {
          border: 2px solid #fff;
        }
        .name {
          color: #fff;
          margin: 0.1rem;
        }
      }
    }
  }
}
.close {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  right: -0.7rem;
  top: -0.6rem;
}
.back{
  position: absolute;
 text-align: center;
  left: 0rem;
  top: -0.7rem;
}
.close:hover {
  filter: brightness(70%);
}
.video,
.cover,
.cover img {
  width: 100%;
  height: 100%;
}
.cover {
  position: relative;
}
.cover img:nth-of-type(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  transform: translate(-100%, -50%);
}
</style>
